<div class="card table-container-card">
    <div class="table-heading" style="border-bottom: none; margin-bottom: 0px !important;">
        <div class="table-title">
            <cds-icon shape="history" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
            <h3>Version History</h3>
        </div>
        <div class="action-group">
            <button type="button" class="btn btn-icon btn-primary btn-md" routerLink="/msp/packages/lifecycle" #tooltip="matTooltip"
                matTooltip="Adjust the package lifecycles" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
                <cds-icon shape="process-on-vm"></cds-icon> Stages
            </button>
        </div>
    </div>
    <clr-datagrid id="datagrid" [clrDgLoading]="isLoading" class="datagrid-compact" style="width: 100%;">
        <clr-dg-column>Package</clr-dg-column>
        <clr-dg-column>Version</clr-dg-column>
        <clr-dg-column>Stage</clr-dg-column>
        <clr-dg-column>Modified On</clr-dg-column>

        <clr-dg-row *ngFor="let item of actionVersions; let i = index">
            <clr-dg-cell>{{ item.actionName }}</clr-dg-cell>
            <clr-dg-cell>{{ item.version }}</clr-dg-cell>
            <clr-dg-cell>
                <select class="clr-select" name="versionStage" clrSelect [(ngModel)]="item.stage"
                    (change)="onVersionStageUpdate(+$event.target.value, item.id)">
                    <option *ngFor="let stage of stageEnum | enumToList; let i = index" value="{{i}}"
                        [selected]="i === item.stage">
                        {{stage}}
                    </option>
                </select>
            </clr-dg-cell>
            <clr-dg-cell>
                {{ item.lastModified === "0001-01-01T00:00:00" ? 'Unknown' : item.lastModified + 'Z' | date:'dd-MM-yyyy
                HH:mm' }}
            </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
            <clr-dg-pagination #lastModifiedTablePagination [(clrDgPage)]="currentPage" [clrDgPageSize]="itemsPerPage"
                [clrDgTotalItems]="actionVersionsCount" (clrDgPageChange)="onChangePage($event)">
                <clr-dg-page-size [(clrPageSizeOptions)]="pageSizeOptions">Items per page</clr-dg-page-size>
                {{lastModifiedTablePagination.firstItem + 1}} - {{lastModifiedTablePagination.lastItem + 1}}
                of {{lastModifiedTablePagination.totalItems}}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>