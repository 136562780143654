import { Component, OnInit, ViewChild } from '@angular/core';
import { ActionVersionDto } from 'src/app/domain/dtos/action-version-dto';
import { PaginationModel } from 'src/app/domain/interfaces/paginationModel';
import { ITag, TagType } from 'src/app/modules/shared/domain/tag';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';
import { TagService } from 'src/app/services/tag.service';

@Component({
  selector: 'app-version-history-page',
  templateUrl: './version-history-page.component.html',
  styleUrls: ['./version-history-page.component.scss'],
})
export class VersionHistoryPageComponent implements OnInit {
  @ViewChild('lastModifiedTablePagination') paginator: { pageSize: number };

  isLoading = false;
  actionVersions: ActionVersionDto[];
  actionVersionsCount: number;
  paginationModel: PaginationModel;
  pageSizeOptions = [40, 60, 80, 100];
  currentPage = 1;
  itemsPerPage = 40;
  lifecycles: ITag[];

  constructor(
    private actionVersionService: ActionversionService,
    private tagService: TagService
  ) {}

  ngOnInit(): void {
    this.paginationModel = { skip: 0, take: this.itemsPerPage };
    this.tagService.GetTags().subscribe({
      next: (data) =>
        (this.lifecycles = data.filter((d) => d.type === TagType.DeployStage)),
    });
    this.getActionVersions();
  }

  onChangePage(index: number) {
    this.paginationModel.skip = (index - 1) * this.paginator.pageSize;
    this.paginationModel.take = this.paginator.pageSize;
    this.itemsPerPage = this.paginator.pageSize;
    this.actionVersions = [];
    this.getActionVersions();
  }

  getActionVersions() {
    this.isLoading = true;
    this.actionVersionService
      .GetActionVersions(this.paginationModel)
      .subscribe((data) => {
        console.log(data.actionVersions);
        this.actionVersions = data.actionVersions;
        this.actionVersionsCount = data.count;
        this.isLoading = false;
      });
  }

  onVersionLifecycleUpdate(lifecycleId: number, versionId: number) {
    const selectedLifecycle = this.lifecycles.filter(
      (t) => t.id === lifecycleId
    );
    this.actionVersionService
      .AddTagToActionVersionByActionVersionId(versionId, selectedLifecycle)
      .subscribe();
  }
}
