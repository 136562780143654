import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Command } from '../../models/command';

@Component({
  selector: 'pb-summary-step',
  templateUrl: './summary-step.component.html',
  styleUrls: ['./summary-step.component.scss'],
})
export class SummaryStepComponent {
  @Input() formData: FormGroup;
  @Input() sequence: Command[] = [];
  @Input() iconUrl?: string;
  @Input() initialVersion?: string;
  @Output() back = new EventEmitter();

  constructor() {}
}
