<div class="card table-container-card">
    <div class="table-heading" style="border-bottom: none; margin-bottom: 0px !important;">
        <div class="table-title">
            <cds-icon shape="list" size="md"></cds-icon>
            <h3>Packages</h3>
        </div>
        <div class="action-group">
            <button type="button" class="btn btn-icon btn-outline btn-md" routerLink="configure-lifecycle"
                #tooltip="matTooltip" matTooltip="Configure auto lifecycle settings" [matTooltipPosition]="'above'"
                matTooltipHideDelay="100">
                <cds-icon shape="cog"></cds-icon> Auto Lifecycle
            </button>
            <button type="button" class="btn btn-icon btn-primary btn-md" routerLink="lifecycle" #tooltip="matTooltip"
                matTooltip="Adjust the package lifecycles" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
                <cds-icon shape="process-on-vm"></cds-icon> Stages
            </button>
        </div>
    </div>
    <clr-datagrid [clrDgLoading]="isLoading" class="h-100">
        <clr-dg-column>Package</clr-dg-column>
        <clr-dg-column>Package Versions</clr-dg-column>
        <clr-dg-column>Latest Version</clr-dg-column>
        <clr-dg-column>Total Subscribers</clr-dg-column>
        <clr-dg-row #refEl (click)="openDetail(refEl)" *clrDgItems="let action of actions" [clrDgItem]="action"
            style="cursor: pointer;">
            <clr-dg-cell>{{action.name}}</clr-dg-cell>
            <clr-dg-cell>{{action.actionVersion.length}}</clr-dg-cell>
            <clr-dg-cell>{{action.actionVersion[action.actionVersion.length - 1].version}}</clr-dg-cell>
            <clr-dg-cell>{{action.subscribedTenants ? action.subscribedTenants.length : 0}}</clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="100">
                <clr-dg-page-size [clrPageSizeOptions]="[15,30,45,60,75,90,100]">Packages per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Packages
            </clr-dg-pagination>
        </clr-dg-footer>
        <ng-template [(clrIfDetail)]="detailState" let-detail (clrIfDetailChange)="onDetailOpen($event)">
            <clr-dg-detail>
                <clr-dg-detail-header>
                    <div style="display: flex; justify-content: space-between;">
                        <span>{{detail.name}}</span>
                        <button class="btn btn-link" aria-label="close details" (click)="closeDetail()">
                            <cds-icon shape="window-close" size="md" style="--color: var(--clr-p1-color)"></cds-icon>
                        </button>
                    </div>
                    <clr-toggle-wrapper>
                        <input type="checkbox" clrToggle name="overrideLifecycleManagement"
                            [(ngModel)]="detail.hasOverridenGlobalAutoLifecycle"
                            (change)="updateAutoLifecycleConfigurationForAction(detail)" />
                        <label>Override Auto Lifecycle</label>
                    </clr-toggle-wrapper>
                    <clr-select-container *ngIf="detail.hasOverridenGlobalAutoLifecycle">
                        <label>Sandbox to Quality Assurance time <cds-icon matTooltipHideDelay="100"
                                matTooltip="Wait time before automatically flowing to Quality Assurance"
                                shape="info-circle" size="'sm'"></cds-icon></label>
                        <select class="clr-select" clrSelect name="testingToAcceptance"
                            [(ngModel)]="detail.flowToAcceptanceInHours"
                            (change)="updateAutoLifecycleConfigurationForAction(detail)">
                            <option [ngValue]="null">Never</option>
                            <option value="1">1 hour</option>
                            <option value="2">2 hours</option>
                            <option value="4">4 hours</option>
                            <option value="8">8 hours</option>
                            <option value="24">1 day</option>
                            <option value="48">2 days</option>
                            <option value="96">4 days</option>
                            <option value="168">1 week</option>
                            <option value="336">2 weeks</option>
                        </select>
                    </clr-select-container>
                    <clr-select-container *ngIf="detail.hasOverridenGlobalAutoLifecycle">
                        <label>Quality Assurance to Operational time<cds-icon matTooltipHideDelay="100"
                                matTooltip="Wait time before automatically flowing to Operational" shape="info-circle"
                                size="'sm'"></cds-icon></label>
                        <select class="clr-select" clrSelect name="testingToAcceptance"
                            [(ngModel)]="detail.flowToProductionInHours"
                            (change)="updateAutoLifecycleConfigurationForAction(detail)">
                            <option [ngValue]="null">Never</option>
                            <option value="1">1 hour</option>
                            <option value="2">2 hours</option>
                            <option value="4">4 hours</option>
                            <option value="8">8 hours</option>
                            <option value="24">1 day</option>
                            <option value="48">2 days</option>
                            <option value="96">4 days</option>
                            <option value="168">1 week</option>
                            <option value="336">2 weeks</option>
                        </select>
                    </clr-select-container>
                </clr-dg-detail-header>
                <clr-dg-detail-body>
                    <clr-tabs>
                        <clr-tab>
                            <button clrTabLink>Versions</button>
                            <clr-tab-content *clrIfActive="true">
                                <clr-datagrid class="datagrid-compact" id="my-datagrid">
                                    <clr-dg-column>Package Version</clr-dg-column>
                                    <clr-dg-column>Stage</clr-dg-column>
                                    <clr-dg-column class="flex-2">Action</clr-dg-column>
                                    <clr-dg-row *clrDgItems="let version of detailState.actionVersion"
                                        [clrDgItem]="version">
                                        <clr-dg-cell>{{version.version}}</clr-dg-cell>
                                        <clr-dg-cell>
                                            <select class="clr-select" name="versionStage" clrSelect
                                                [(ngModel)]="version.stage"
                                                (change)="onVersionStageUpdate(+$event.target.value, version.id)">
                                                <option *ngFor="let stage of stageEnum | enumToList; let i = index"
                                                    value="{{i}}" [selected]="i === version.stage">
                                                    {{stage}}
                                                </option>
                                            </select>
                                        </clr-dg-cell>
                                        <clr-dg-cell class="flex-2">
                                            <button [disabled]="!version.hasBuilderJson" type="button"
                                                class="btn btn-icon btn-sm m-0" routerLink="/msp/package-builder"
                                                [queryParams]="{id: version.id}" #tooltip="matTooltip"
                                                matTooltip="Open the package builder" [matTooltipPosition]="'above'"
                                                matTooltipHideDelay="100">
                                                <cds-icon shape="pencil"></cds-icon>
                                            </button>
                                            <div class="mx-1"></div>
                                            <button (click)="moveActionVersionToRecycleBin(version.id)"
                                                [disabled]="isBeingDeleted(version.id)" type="button"
                                                class="btn btn-icon btn-danger-outline btn-sm m-0" #tooltip="matTooltip"
                                                matTooltip="Move to recycle bin" [matTooltipPosition]="'above'"
                                                matTooltipHideDelay="100">
                                                <cds-icon *ngIf="!isBeingDeleted(version.id)" shape="trash"></cds-icon>
                                                <cds-icon *ngIf="isBeingDeleted(version.id)" shape=""></cds-icon>
                                            </button>
                                            <div class="mx-1"></div>
                                            <clr-toggle-wrapper>
                                                <input type="checkbox" clrToggle name="overrideLifecycleManagement"
                                                    [ngModel]="!version.isPreventingAutomaticLifecycleUpdates"
                                                    (change)="updateVersionAutoLifecycle(version, version.isPreventingAutomaticLifecycleUpdates)" />
                                                <label>Auto Lifecycle</label>
                                            </clr-toggle-wrapper>
                                        </clr-dg-cell>
                                    </clr-dg-row>
                                    <clr-dg-footer>
                                        <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                                            <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Versions per
                                                page</clr-dg-page-size>
                                            {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of
                                            {{paginationBP.totalItems}}
                                            versions
                                        </clr-dg-pagination>
                                    </clr-dg-footer>
                                </clr-datagrid>
                            </clr-tab-content>
                        </clr-tab>
                        <clr-tab>
                            <button clrTabLink>Subscribers</button>
                            <clr-tab-content>
                                <clr-datagrid class="datagrid-compact h-100">
                                    <clr-dg-column class="flex-1">no.</clr-dg-column>
                                    <clr-dg-column class="flex-7">Customer</clr-dg-column>
                                    <clr-dg-row *clrDgItems="let tenant of detailState.subscribedTenants; let i = index"
                                        [clrDgItem]="tenant">
                                        <clr-dg-cell class="flex-1">{{i + 1}}</clr-dg-cell>
                                        <clr-dg-cell class="flex-7">{{tenant.name}}</clr-dg-cell>
                                    </clr-dg-row>
                                    <clr-dg-footer>
                                        <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                                            <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Endpoints per
                                                page</clr-dg-page-size>
                                            {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of
                                            {{paginationBP.totalItems}}
                                            Subscribers
                                        </clr-dg-pagination>
                                    </clr-dg-footer>
                                </clr-datagrid>
                            </clr-tab-content>
                        </clr-tab>
                    </clr-tabs>
                </clr-dg-detail-body>
            </clr-dg-detail>
        </ng-template>
    </clr-datagrid>
</div>