<div class="row h-100">
    <div class="col-lg-5 col main-column">
        <b>General Information</b>
        <div class="p-3 my-3 general-information-container">
            <div class="col alert alert-app-level alert-danger mb-3" role="alert" *ngIf="initialVersion === formData?.get('version').value">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <cds-icon class="alert-icon" shape="error-standard"></cds-icon>
                        </div>
                        <div class="alert-text">
                            The version of the edited package has not been updated
                        </div>
                        <div class="alert-actions">
                            <button (click)="back.emit(undefined)" class="btn alert-action">Go Back</button>
                        </div>
                    </div>
                </div>
            </div>
            <img [attr.src]="iconUrl ? iconUrl : 'assets/nimdeploy-logo-shadow.svg'" class="mb-2 package-image" />
            <div class="package-name">{{formData?.value.title}}</div>
            <div class="row mt-2" *ngFor="let item of formData?.value | keyvalue">
                <div class="col-md-6 p4 m-0">
                    {{item.key | camelCaseSplitter}}
                </div>
                <div *ngIf="item.key !== 'icon'" class="col-md-6 text-right p3 m-0 text-truncate">
                    {{item.value}}
                </div>
                <div *ngIf="item.key === 'icon'" class="image-file col-md-6 text-right p3 m-0">
                    {{item.value?.name}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-7 col main-column">
        <b>Sequence</b>
        <div class="my-3">
            <pb-expandable-command-item *ngFor="let command of sequence" [command]="command"
                [isReadOnly]="true"></pb-expandable-command-item>
        </div>
    </div>
</div>