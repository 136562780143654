import { Component, OnInit, ViewChild } from '@angular/core';
import { ActionVersionDto } from 'src/app/domain/dtos/action-version-dto';
import { PaginationModel } from 'src/app/domain/interfaces/paginationModel';
import { Stage } from 'src/app/modules/shared/domain/enums/stage';
import { ITag } from 'src/app/modules/shared/domain/tag';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';

@Component({
  selector: 'app-msp-version-history-page',
  templateUrl: './msp-version-history-page.component.html',
  styleUrls: ['./msp-version-history-page.component.scss'],
})
export class MspVersionHistoryPageComponent implements OnInit {
  @ViewChild('lastModifiedTablePagination') paginator: { pageSize: number };

  isLoading = false;
  actionVersions: ActionVersionDto[];
  actionVersionsCount: number;
  paginationModel: PaginationModel;
  pageSizeOptions = [40, 60, 80, 100];
  currentPage = 1;
  itemsPerPage = 40;
  lifecycles: ITag[];
  stageEnum = Stage;

  constructor(private actionVersionService: ActionversionService) {}

  ngOnInit(): void {
    this.paginationModel = { skip: 0, take: this.itemsPerPage };
    this.getActionVersions();
  }

  onChangePage(index: number) {
    this.paginationModel.skip = (index - 1) * this.paginator.pageSize;
    this.paginationModel.take = this.paginator.pageSize;
    this.itemsPerPage = this.paginator.pageSize;
    this.actionVersions = [];
    this.getActionVersions();
  }

  getActionVersions() {
    this.isLoading = true;
    this.actionVersionService
      .GetActionVersions(this.paginationModel)
      .subscribe((data) => {
        console.log(data.actionVersions);
        this.actionVersions = data.actionVersions;
        this.actionVersionsCount = data.count;
        this.isLoading = false;
      });
  }

  onVersionStageUpdate(stage: Stage, versionId: number) {
    this.actionVersionService
      .setActionVersionStage(versionId, stage)
      .subscribe();
  }
}
