<clr-wizard #wizard class="clr-wizard--inline" [clrWizardOpen]="true" [clrWizardClosable]="false">
    <clr-wizard-title>Build Package</clr-wizard-title>

    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>

    <clr-wizard-page #generalInfoPage [clrWizardPageNextDisabled]="!isGeneralInformationFormValid">
        <clr-progress-bar [clrLoop]="true" *ngIf="isLoading">

        </clr-progress-bar>
        <ng-template clrPageTitle>General Information</ng-template>
        <pb-general-information-step [hidden]="isLoading" [builderData]="editingBuilderData"
            (generalInformationFormEmitter)="setGeneralInformationForm($event)">
        </pb-general-information-step>
    </clr-wizard-page>

    <clr-wizard-page>
        <ng-template clrPageNavTitle>File Management</ng-template>
        <ng-template clrPageTitle>Manage Files</ng-template>
        <pb-file-management [builderData]="editingBuilderData"
            (packageFilesEmitter)="onFilesChanged($event)"></pb-file-management>
    </clr-wizard-page>

    <clr-wizard-page (clrWizardPageCustomButton)="doCustomClick($event)">
        <ng-template clrPageTitle>Sequence Builder</ng-template>
        <clr-alert [(clrAlertClosed)]="isSequenceValid" class="alert-sm" [clrAlertType]="'danger'">
            <clr-alert-item>
                Your sequence is invalid. Please make sure to fill in all of the required fields.
            </clr-alert-item>
        </clr-alert>
        <pb-sequence-builder-step [builderData]="editingBuilderData" [filePaths]="filePaths"
            [defaultValues]="defaultValues" (onChangeEvent)="onSequenceChanged($event)"></pb-sequence-builder-step>
        <ng-template clrPageButtons>
            <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
            <clr-wizard-button class="btn btn-primary" [type]="'sequence-next'">Next</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>

    <clr-wizard-page (clrWizardPageCustomButton)="doCustomClick($event)">
        <ng-template clrPageTitle>Summary</ng-template>
        <pb-summary-step [iconUrl]="iconUrl" [formData]="generalInformationForm" [initialVersion]="initialVersion"
            (back)="doCustomClick('general-info')" [sequence]="sequence"></pb-summary-step>
        <ng-template clrPageButtons>
            <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
            <clr-wizard-button class="btn btn-primary" [type]="'build'">Build & Download Package</clr-wizard-button>
            <clr-wizard-button class="btn btn-primary" [type]="'publish'">Build & Publish Package</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>

    <clr-wizard-page (clrWizardPageCustomButton)="doCustomClick($event)">
        <ng-template clrPageTitle>
            Building package...
        </ng-template>
        <ng-template clrPageNavTitle>
            Finalization
        </ng-template>
        <pb-building-step [imageUri]="iconUrl" [packageName]="generalInformationForm?.value.title"
            [message]="currentProgressMessage" [isDone]="isDone" [isError]="isError"></pb-building-step>
        <ng-template clrPageButtons>
            <clr-wizard-button *ngIf="isDone || isError" class="btn btn-primary" [type]="'refresh'">Build another
                package</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>

</clr-wizard>