<div class="main-content">
  <img id="image" #photo />
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-warning card-header-icon">
            <div class="card-icon">
              <cds-icon shape="computer" size="md"></cds-icon>
            </div>
            <p class="card-category">Total Endpoints</p>
            <h3 class="card-title">
              {{ endpointCount }}
              <small>ENDPOINTS</small>
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <img src="/assets/icons/spreadsheet.svg" />
              <a (click)="routeTo($event, '/configuration/endpoints')" href="/configuration/endpoints">
                View Endpoints</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-success card-header-icon">
            <div class="card-icon">
              <cds-icon shape="devices" size="md"></cds-icon>
            </div>
            <p class="card-category">Groups</p>
            <h3 class="card-title">
              {{ groupCount }}
              <small>GROUPS</small>
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <img src="/assets/icons/spreadsheet.svg" />
              <a (click)="routeTo($event, '/configuration/groups')" href="/configuration/groups">
                View Groups</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-success card-header-icon">
            <div class="card-icon">
              <cds-icon shape="list" size="md"></cds-icon>
            </div>
            <p class="card-category">Packages</p>
            <h3 class="card-title">
              {{ actionCount }}
              <small>PACKAGES</small>
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <img src="/assets/icons/spreadsheet.svg" />
              <a (click)="routeTo($event, '/configuration/actions')" href="/configuration/actions">
                View Packages</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-success card-header-icon">
            <div class="card-icon">
              <cds-icon shape="applications" size="md"></cds-icon>
            </div>
            <p class="card-category">Blueprints</p>
            <h3 class="card-title">
              {{ blueprintCount }}
              <small>BLUEPRINTS</small>
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <img src="/assets/icons/spreadsheet.svg" />
              <a (click)="routeTo($event, '/blueprints')" href="/blueprints">
                View Blueprints</a>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex gap-4 flex-lg-row flex-md-column">
        <div class="card table-container">
          <div class="card-header card-header-warning">
            <h4 class="card-title">Last executed Packages</h4>
            <p class="card-category">
              Last executed Packages as of today,
              {{ currentDate | date : "dd-MM-yyyy, h:mm a" }}
            </p>
          </div>

          <div class="p-3">
            <clr-datagrid [clrDgLoading]="pageIsLoading">
              <clr-dg-column>Package</clr-dg-column>
              <clr-dg-column>Endpoint</clr-dg-column>
              <clr-dg-column>Status</clr-dg-column>
              <clr-dg-column>Install Date & Time</clr-dg-column>

              <clr-dg-row *ngFor="let item of assignmentActions; let i = index">
                <clr-dg-cell>{{ item.action.name }}</clr-dg-cell>
                <clr-dg-cell>{{ item.assignment.endpoint.name }}</clr-dg-cell>
                <clr-dg-cell>{{ item.actionStatus | enumToString }}</clr-dg-cell>
                <clr-dg-cell>
                  {{ item.installDate === "0001-01-01T00:00:00" ? 'Unknown' : item.installDate + 'Z' | date:'dd-MM-yyyy
                  HH:mm' }}
                </clr-dg-cell>
              </clr-dg-row>

              <clr-dg-footer>
                <clr-dg-pagination #pagination [(clrDgPage)]="currentPage" [clrDgPageSize]="itemsPerPage"
                  [clrDgTotalItems]="assignmentActionsLength" (clrDgPageChange)="onChangePage($event)">
                  <clr-dg-page-size [(clrPageSizeOptions)]="pageSizeOptions">Items per page</clr-dg-page-size>
                  {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                  of {{pagination.totalItems}}
                </clr-dg-pagination>
              </clr-dg-footer>
            </clr-datagrid>
          </div>
        </div>
        <div class="card table-container">
          <div class="card-header card-header-warning">
            <h4 class="card-title">
              <a routerLink="/version-history" style="color: inherit !important;">Last modified
                Packages <cds-icon shape="pop-out"></cds-icon></a>
            </h4>
            <p class="card-category">
              Last modified Packages as of today,
              {{ currentDate | date : "dd-MM-yyyy, h:mm a" }}
            </p>
          </div>
          <div class="p-3">
            <nd-last-modified-packages></nd-last-modified-packages>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>