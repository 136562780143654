<div class="card table-container-card">
  <div class="table-heading ">
    <div class="table-title">
      <cds-icon shape="computer" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
      <h3>Endpoints</h3>
    </div>
    <div class="action-group">
      <cds-icon (click)="showColumnVisibilityModal = true" shape="cog" class="action-settings me-1" size="md"
        style="cursor: pointer;"></cds-icon>

      <button [disabled]="!singleAction && !multiAction" class="action-item" class="btn btn-icon btn-danger  btn-md"
        (click)="openDeleteModal = true" #tooltip="matTooltip" matTooltip="Delete Endpoint"
        [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <img src="../../../assets/icons/trash.svg" />
      </button>

      <button [disabled]="1>selected.length" type="button" class="btn btn-icon btn-primary btn-md"
        (click)="addEndpointsToTag = true" #tooltip="matTooltip" matTooltip="Add Tags to Endpoints"
        [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <img src="../../../assets/icons/tag.svg" />
      </button>
    </div>
  </div>
  <clr-datagrid class="datagrid-compact" [clrDgLoading]="isLoadingEndpoints" [clrDgSelected]="selected"
    (clrDgSelectedChange)="selectionChanged($event)" id="my-datagrid" style="margin-top: 0px !important;">
    <clr-dg-column [clrDgField]="'name'" [style.width.px]="200">
      <div>
        Endpoint
      </div>
    </clr-dg-column>
    <clr-dg-column [style.width.px]="120" [contextMenu]="tableMenu" [contextMenuSubject]="this">Status</clr-dg-column>
    <clr-dg-column *ngIf="tableColumnVisibility.groupsColumnIsVisible" cdkDropList #tableOptions="cdkDropList"
      [cdkDropListConnectedTo]="[deleteBox]" [cdkDropListData]="emptyData" [contextMenu]="tableMenu"
      [contextMenuSubject]="this">
      <div>
        Groups
      </div>
    </clr-dg-column>
    <clr-dg-column *ngIf="tableColumnVisibility.lifecycleColumnIsVisible" cdkDropList #tableOptions="cdkDropList"
      [cdkDropListConnectedTo]="[deleteBox]" [contextMenu]="tableMenu" [contextMenuSubject]="this">
      <div>
        Lifecycle
      </div>
    </clr-dg-column>
    <clr-dg-column cdkDropList *ngIf="tableColumnVisibility.serialNumberColumnIsVisible" #tableOptions="cdkDropList"
      [cdkDropListConnectedTo]="[deleteBox]" [contextMenu]="tableMenu" [contextMenuSubject]="this">
      <div>
        Serial Number
      </div>
    </clr-dg-column>
    <clr-dg-column cdkDropList *ngIf="tableColumnVisibility.serviceVersionColumnIsVisible" #tableOptions="cdkDropList"
      [cdkDropListConnectedTo]="[deleteBox]" [contextMenu]="tableMenu" [contextMenuSubject]="this">
      <div>
        Service version
      </div>
    </clr-dg-column>
    <clr-dg-column cdkDropList *ngIf="tableColumnVisibility.trayappVersionColumnIsVisible" #tableOptions="cdkDropList"
      [cdkDropListConnectedTo]="[deleteBox]" [contextMenu]="tableMenu" [contextMenuSubject]="this">
      <div>
        Tray app version
      </div>
    </clr-dg-column>
    <clr-dg-column cdkDropList *ngIf="tableColumnVisibility.lastConnectionColumnIsVisible" #tableOptions="cdkDropList"
      [cdkDropListConnectedTo]="[deleteBox]" [contextMenu]="tableMenu" [contextMenuSubject]="this">
      <div>
        Last Connection
      </div>
    </clr-dg-column>
    <clr-dg-column cdkDropList *ngIf="tableColumnVisibility.installDateColumnIsVisible" #tableOptions="cdkDropList"
      [cdkDropListConnectedTo]="[deleteBox]" [contextMenu]="tableMenu" [contextMenuSubject]="this">
      <div>
        Install Date
      </div>
    </clr-dg-column>
    <clr-dg-row *clrDgItems="let data of endpoints" [clrDgItem]="data">
      <clr-dg-cell [contextMenu]="itemMenu" [contextMenuSubject]="data" #refEl (click)="clickName(refEl)"
        style="cursor: pointer;">
        {{data.name}}
      </clr-dg-cell>
      <clr-dg-cell #refEl (click)="clickName(refEl)" style="cursor: pointer;">{{data.endpointStatus.name}}</clr-dg-cell>
      <clr-dg-cell *ngIf="tableColumnVisibility.groupsColumnIsVisible" #refEl style="cursor: pointer;">
        <nd-group-badge [groups]="data.endpointGrouping"></nd-group-badge>
      </clr-dg-cell>
      <clr-dg-cell *ngIf="tableColumnVisibility.lifecycleColumnIsVisible" #refEl style="cursor: pointer;">
        <nd-lifecycle-badge [tag]="data.lowestTag"></nd-lifecycle-badge>
      </clr-dg-cell>
      <clr-dg-cell *ngIf="tableColumnVisibility.serialNumberColumnIsVisible" #refEl (click)="clickName(refEl)"
        style="cursor: pointer;">
        {{data.serialNumber}}</clr-dg-cell>
      <clr-dg-cell *ngIf="tableColumnVisibility.serviceVersionColumnIsVisible" refEl (click)="clickName(refEl)"
        style="cursor: pointer;">
        {{data.lastKnownServiceVersion ? data.lastKnownServiceVersion : 'Unknown'}}</clr-dg-cell>
      <clr-dg-cell *ngIf="tableColumnVisibility.trayappVersionColumnIsVisible" #refEl (click)="clickName(refEl)"
        style="cursor: pointer;">
        {{data.lastKnownTrayAppVersion ? data.lastKnownTrayAppVersion : 'Unknown'}}</clr-dg-cell>
      <clr-dg-cell *ngIf="tableColumnVisibility.lastConnectionColumnIsVisible" #refEl (click)="clickName(refEl)"
        style="cursor: pointer;">
        {{data.lastConnection ? (data.lastConnection + 'Z' | date:'dd-MM-yyyy HH:mm') : 'Unknown'}}</clr-dg-cell>
      <clr-dg-cell *ngIf="tableColumnVisibility.installDateColumnIsVisible" #refEl (click)="clickName(refEl)"
        style="cursor: pointer;">
        {{data.installDate | date:'dd-MM-yyyy HH:mm'}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="100">
        <clr-dg-page-size [clrPageSizeOptions]="[15,30,45,60,75,90,100]">Endpoints per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} endpoints
      </clr-dg-pagination>
    </clr-dg-footer>

    <ng-template [(clrIfDetail)]="detailState" let-detail (clrIfDetailChange)="onDetailOpen($event)">
      <clr-dg-detail>
        <clr-dg-detail-header [clrLoading]="endpointDetailsLoading" class="w-100">
          <div>
            <div id="detail-title">
              <h3 class="mb-3">{{detail.name}}</h3>
              <div class="action-group btn-icon gap-1">
                <button (click)="RouteAssignmentDetails(detail.id)" type="button"
                  class="btn btn-icon btn-success btn-md" #tooltip="matTooltip" matTooltip="Edit Endpoint Actions"
                  [matTooltipPosition]="'above'" matTooltipHideDelay="100">
                  <clr-icon shape="note"></clr-icon>
                  Edit
                </button>
                <button (click)="RouteEndpointStatus(detail.id)" type="button" class="btn btn-icon btn-primary btn-md"
                  #tooltip="matTooltip" matTooltip="View logs" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
                  <clr-icon shape="list"></clr-icon>
                  Logs
                </button>
                <button (click)="refreshActions()" class="btn btn-icon btn-primary btn-md" #tooltip="matTooltip"
                  matTooltip="Refresh Actions" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
                  <clr-icon shape="sync"></clr-icon>
                </button>
                <button (click)="FailedActions()" [disabled]="!failedActions" type="button"
                  class="btn btn-icon btn-warning btn-md" #tooltip="matTooltip" matTooltip="Reset Failed Actions"
                  [matTooltipPosition]="'above'" matTooltipHideDelay="100">
                  <clr-icon shape="sync"></clr-icon>
                </button>
                <button class="btn btn-link" aria-label="close details" (click)="closeDetail()">
                  <cds-icon shape="window-close" size="md" style="--color: var(--clr-p1-color)"></cds-icon>
                </button>
              </div>
            </div>
            <table class="endpoint-details-grid">
              <tr>
                <td>
                  <p class="mt-0 lh-base">Lifecycle</p>
                </td>
                <td> <nd-lifecycle-badge [tag]="detail.lowestTag"></nd-lifecycle-badge>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mt-0 lh-base">Serial number </p>
                </td>
                <td>
                  <p class="mt-0 lh-base">{{detail.serialNumber}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mt-0 lh-base">Install date </p>
                </td>
                <td>
                  <p class="mt-0 lh-base">{{detail.installDate | date:'dd-MM-yyyy HH:mm'}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mt-0 lh-base">Service version </p>
                </td>
                <td>
                  <p class="mt-0 lh-base">{{detail.lastKnownServiceVersion ? detail.lastKnownServiceVersion :
                    'Unknown'}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mt-0 lh-base">Tray app version </p>
                </td>
                <td>
                  <p class="mt-0 lh-base">{{detail.lastKnownTrayAppVersion ? detail.lastKnownTrayAppVersion :
                    'Unknown'}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mt-0 lh-base">Ready to deploy </p>
                </td>
                <td> <clr-toggle-container>
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle name="options" required value="option1"
                        [(ngModel)]="readyToDeploy" (change)="ToggleReadyToDeploy(readyToDeploy)" />
                    </clr-toggle-wrapper>
                  </clr-toggle-container></td>
              </tr>
            </table>
          </div>
        </clr-dg-detail-header>

        <clr-dg-detail-body>
          <clr-tabs>
            <clr-tab>
              <button clrTabLink>Packages</button>
              <clr-tab-content *clrIfActive="true">
                <clr-datagrid class="datagrid-compact my-datagrid" [clrDgLoading]="actionsLoading">
                  <clr-dg-column [clrDgField]="'action.name'">Package</clr-dg-column>
                  <clr-dg-column [style.width.px]="175">Originator</clr-dg-column>
                  <clr-dg-column [style.width.px]="50">Optional</clr-dg-column>
                  <clr-dg-column [clrDgField]="'actionStatus'" [style.width.px]="50">Status</clr-dg-column>
                  <clr-dg-column>Actions</clr-dg-column>
                  <clr-dg-column [clrDgField]="'installDate'">Install Date & Time</clr-dg-column>
                  <clr-dg-row *clrDgItems="let assignment of currentAssignmentActions" [clrDgItem]="assignment">
                    <clr-dg-cell>{{assignment.action.name}}</clr-dg-cell>
                    <clr-dg-cell *ngIf="assignment.blueprintId">
                      <div matTooltip="{{assignment.blueprint.name}}" class="originator-cell">
                        <div>
                          {{ assignment.blueprint.name | truncate: 10 }}
                        </div>
                        <cds-tag status="info">
                          Blueprint
                        </cds-tag>
                      </div>
                    </clr-dg-cell>
                    <clr-dg-cell *ngIf="!assignment.blueprintId && assignment.endpointGroupId">
                      <div matTooltip="{{assignment.endpointGroup.name}}" class="originator-cell">
                        <div>
                          {{ assignment.endpointGroup.name | truncate: 10 }}
                        </div>
                        <cds-tag status="info">
                          Group
                        </cds-tag>
                      </div>
                    </clr-dg-cell>
                    <clr-dg-cell *ngIf="!assignment.blueprintId && !assignment.endpointGroupId">
                      <div matTooltip="{{detail.name}}" class="originator-cell">
                        <div>
                          {{ detail.name | truncate: 10 }}
                        </div>
                        <cds-tag status="warning">
                          Endpoint
                        </cds-tag>
                      </div>
                    </clr-dg-cell>
                    <clr-dg-cell [class.disabled-input]="assignment.endpointGroupId">
                      <input type="checkbox" clrCheckbox [(ngModel)]="assignment.isOptional"
                        (change)="updateActionOptionalStatus(assignment)" [disabled]="assignment.endpointGroupId" />
                    </clr-dg-cell>
                    <clr-dg-cell [ngSwitch]="assignment.actionStatus">
                      <cds-icon *ngSwitchCase="actionStatus.UpToDate" class="cursor-pointer" matTooltip="Up to date"
                        shape="success-standard" status="success" size="md" solid></cds-icon>
                      <cds-icon *ngSwitchCase="actionStatus.NotUpToDate" class="cursor-pointer"
                        matTooltip="Not up to date" shape="warning-standard" status="warning" size="md"
                        solid></cds-icon>
                      <cds-icon *ngSwitchCase="actionStatus.Failed" class="cursor-pointer" matTooltip="Failed"
                        shape="times-circle" status="danger" size="md" solid></cds-icon>
                      <span *ngSwitchDefault>{{assignment.actionStatus | enumToString}}</span>
                    </clr-dg-cell>
                    <clr-dg-cell>
                      <button
                        [disabled]="assignment.actionStatus === actionStatus.NotStarted || assignment.actionStatus === actionStatus.InProgress"
                        (click)="restartAssignmentAction(assignment.id)"
                        type="button" class="btn btn-sm btn-link btn-icon" matTooltip="Restart deployment">
                        <cds-icon shape="refresh"></cds-icon>
                      </button>
                    </clr-dg-cell>
                    <clr-dg-cell>
                      {{ assignment.installDate === "0001-01-01T00:00:00" ? 'Unknown' : assignment.installDate + 'Z' |
                      date:'dd-MM-yyyy HH:mm' }}
                    </clr-dg-cell>
                  </clr-dg-row>

                  <clr-dg-footer>
                    <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Packages per page</clr-dg-page-size>
                      {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of {{paginationBP.totalItems}}
                      Packages
                    </clr-dg-pagination>

                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Groups</button>
              <clr-tab-content>
                <clr-datagrid class="datagrid-compact my-datagrid">
                  <clr-dg-column>Group</clr-dg-column>
                  <clr-dg-column>Lifecycle</clr-dg-column>
                  <clr-dg-column>Id</clr-dg-column>
                  <clr-dg-row *ngFor="let grouping of detail.endpointGrouping">
                    <clr-dg-cell>
                      {{ grouping.endpointGroup.name }}
                    </clr-dg-cell>
                    <clr-dg-cell>
                      <nd-lifecycle-badge *ngIf="grouping.endpointGroup?.endpointGroupTag?.length"
                        [tag]="grouping.endpointGroup.endpointGroupTag[0].tag"></nd-lifecycle-badge>
                    </clr-dg-cell>
                    <clr-dg-cell>
                      {{ grouping.endpointGroup.id }}
                    </clr-dg-cell>
                  </clr-dg-row>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Schedules</button>
              <clr-tab-content>
                <nd-schedule-picking-tab [endpoint]="detail"></nd-schedule-picking-tab>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Applications</button>
              <clr-tab-content *clrIfActive>
                <nd-endpoint-applications-tab [endpoint]="detail" [packages]="actions"></nd-endpoint-applications-tab>
              </clr-tab-content>
            </clr-tab>
          </clr-tabs>
        </clr-dg-detail-body>
      </clr-dg-detail>
    </ng-template>
  </clr-datagrid>
</div>

<clr-modal [(clrModalOpen)]="openDeleteModal">
  <h3 class="modal-title">Delete Endpoints?</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">No</button>
    <button type="button" class="btn btn-primary" (click)="DeleteEndpoint(selected)">Yes</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showColumnVisibilityModal" [clrModalSize]="'sm'" [clrModalClosable]="false">
  <h3 class="modal-title">Show Columns</h3>
  <div class="modal-body">
    <form class="clr-form clr-form-horizontal">
      <div class="clr-form-control">
        <label class="clr-control-label">Group</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-1" required value="option1"
              [(ngModel)]="tableColumnVisibility.groupsColumnIsVisible" [ngModelOptions]="{standalone: true}" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Lifecycle</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-5" required value="option5"
              [(ngModel)]="tableColumnVisibility.lifecycleColumnIsVisible" [ngModelOptions]="{standalone: true}" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Serial Number</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-2" required value="option2"
              [(ngModel)]="tableColumnVisibility.serialNumberColumnIsVisible" [ngModelOptions]="{standalone: true}" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Service Version</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-7" required value="option7"
              [(ngModel)]="tableColumnVisibility.serviceVersionColumnIsVisible" [ngModelOptions]="{standalone: true}" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Tray App Version</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-8" required value="option8"
              [(ngModel)]="tableColumnVisibility.trayappVersionColumnIsVisible" [ngModelOptions]="{standalone: true}" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Last Connection</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-6" required value="option6"
              [(ngModel)]="tableColumnVisibility.lastConnectionColumnIsVisible" [ngModelOptions]="{standalone: true}" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Install Date</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-3" required value="option3"
              [(ngModel)]="tableColumnVisibility.installDateColumnIsVisible" [ngModelOptions]="{standalone: true}" />
          </clr-checkbox-wrapper>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="handleOkColumnVisibilityModal()">Ok</button>
  </div>
</clr-modal>

<nd-add-tags-modal [title]="'Add Tags To Endpoint'" [(modalIsVisible)]="addEndpointsToTag" [systemTags]="systemTags"
  [userTags]="tags" (selectedTagsEmitter)="handleOkTagModal($event)"
  (closeAddTagsModalEmitter)="handleCancelTagModal()">
</nd-add-tags-modal>

<context-menu #itemMenu>
  <ng-template contextMenuItem let-item (execute)="RouteAssignmentDetails($event.item.id)">
    <p style="margin: 0px 10px 0px 10px;">Edit {{item.name}} Actions</p>
    <div class="dropdown-divider" role="separator" aria-hidden="true"></div>

  </ng-template>
  <ng-template contextMenuItem let-item (execute)="RouteEndpointStatus($event.item.id)">
    <p style="margin: 0px 10px 0px 10px;">{{item.name}} Action Status</p>
  </ng-template>
</context-menu>

<context-menu #tableMenu>
  <ng-template passive="true" contextMenuItem let-item>
    <form class="clr-form clr-form-horizontal">
      <div class="clr-form-control">
        <label class="clr-control-label">Group</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-1" required value="option1"
              [(ngModel)]="tableColumnVisibility.groupsColumnIsVisible" [ngModelOptions]="{standalone: true}"
              (change)="setColumnVisibility()" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Lifecycle</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-5" required value="option5"
              [(ngModel)]="tableColumnVisibility.lifecycleColumnIsVisible" [ngModelOptions]="{standalone: true}"
              (change)="setColumnVisibility()" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Serial Number</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-2" required value="option2"
              [(ngModel)]="tableColumnVisibility.serialNumberColumnIsVisible" [ngModelOptions]="{standalone: true}"
              (change)="setColumnVisibility()" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Service Version</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-7" required value="option7"
              [(ngModel)]="tableColumnVisibility.serviceVersionColumnIsVisible" [ngModelOptions]="{standalone: true}"
              (change)="setColumnVisibility()" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Tray App Version</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-8" required value="option8"
              [(ngModel)]="tableColumnVisibility.trayappVersionColumnIsVisible" [ngModelOptions]="{standalone: true}"
              (change)="setColumnVisibility()" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Last Connection</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-6" required value="option6"
              [(ngModel)]="tableColumnVisibility.lastConnectionColumnIsVisible" [ngModelOptions]="{standalone: true}"
              (change)="setColumnVisibility()" />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label">Install Date</label>
        <div class="clr-control-container">
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options-3" required value="option3"
              [(ngModel)]="tableColumnVisibility.installDateColumnIsVisible" [ngModelOptions]="{standalone: true}"
              (change)="setColumnVisibility()" />
          </clr-checkbox-wrapper>
        </div>
      </div>
    </form>
  </ng-template>
</context-menu>

<app-notify-me #cmp></app-notify-me>