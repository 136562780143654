import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommandSpecification } from '../../models/command-specification';
import { Command, Parameter } from '../../models/command';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommandService } from '../../services/command.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BuilderData } from '../../models/builder';

@Component({
  selector: 'pb-sequence-builder-step',
  templateUrl: './sequence-builder-step.component.html',
  styleUrls: ['./sequence-builder-step.component.scss'],
})
export class SequenceBuilderStepComponent implements OnInit, OnChanges {
  @Input() filePaths: string[] = [];
  @Output() onChangeEvent = new EventEmitter<Command[]>();
  @Input() builderData?: BuilderData;
  @Input() defaultValues: {
    [key: string]: { [key: string]: string | boolean };
  } = {};
  availableCommands: CommandSpecification[] = [];
  selectedCommands: Command[] = [];
  query: string;

  constructor(
    private commandService: CommandService,
    private snackBarService: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.commandService.getCommandSpecifications().subscribe({
      next: (data) => (this.availableCommands = data),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.builderData && this.builderData) {
      this.selectedCommands = this.builderData.sequence.map((s) => {
        const upToDateCommandSpecification = this.availableCommands?.find(
          (c) => c.name === s.specification.name
        );
        if (upToDateCommandSpecification) {
          const parameters: Parameter[] =
            upToDateCommandSpecification.parameters.map((spec) => {
              return {
                name: spec.name,
                value: s.parameters.find(
                  (p) => p.name.toLowerCase() === spec.name.toLowerCase()
                )?.value,
              };
            });
          return new Command(upToDateCommandSpecification, parameters);
        }
        return new Command(s.specification, s.parameters);
      });
      this.onChangeEvent.emit(this.selectedCommands);
    }
  }

  addCommand(command: CommandSpecification): void {
    this.selectedCommands.push(
      new Command(
        command,
        command.parameters.map((p) => {
          return {
            name: p.name,
          };
        })
      )
    );
    this.onChangeEvent.emit(this.selectedCommands);
  }

  drop(event: CdkDragDrop<Command>): void {
    moveItemInArray(
      this.selectedCommands,
      event.previousIndex,
      event.currentIndex
    );
    this.onChangeEvent.emit(this.selectedCommands);
  }

  remove(event: CdkDragDrop<Command>): void {
    this.delete(event.previousIndex);
  }

  delete(index: number): void {
    const deletedCommand = this.selectedCommands.splice(index, 1);
    this.onChangeEvent.emit(this.selectedCommands);
    this.showDeleteSnackbar(deletedCommand[0], index);
  }

  showDeleteSnackbar(command: Command, index: number) {
    const snackBarRef = this.snackBarService.open(
      `"${command.specification.name}" has been deleted.`,
      'Undo',
      { duration: 5000 }
    );

    snackBarRef.onAction().subscribe(() => {
      this.selectedCommands.push(command);
      moveItemInArray(
        this.selectedCommands,
        this.selectedCommands.length - 1,
        index
      );
      this.onChangeEvent.emit(this.selectedCommands);
    });
  }

  filterCallback = (spec: CommandSpecification): boolean =>
    !this.query ||
    (spec.name?.toUpperCase().includes(this.query.toUpperCase()) ?? false);
}
